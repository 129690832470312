import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en_US from "./languages/v1/en_US.json";
import zh_CN from "./languages/v1/zh_CN.json";
import en_US_v2 from "./languages/v2/en_US.json";
import zh_CN_v2 from "./languages/v2/zh_CN.json";
import { languages } from "zlink-front";
import { deepObjectMerge } from "@/utils/index"

const resources = {
  'en-US': {
    translation: deepObjectMerge(languages.enUS, en_US, en_US_v2)
  },
  'zh-CN': {
    translation: deepObjectMerge(languages.zhCN, zh_CN, zh_CN_v2)
  },
};
i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem('language') || 'zh-CN',
  interpolation: {
    escapeValue: false
  }
});

export default i18n;