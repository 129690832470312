// import React, { useState, useEffect } from "react";
// import { Drawer, Form, Input, Select, Button, DatePicker } from "antd";
// import BaseFileUpload from "@/components/Basic/BaseFileUpload";
// import { ProFormText } from "@ant-design/pro-form";
// import SelectOrgPersonModal from "@/components/SelectOrgPersonModal/Index";
// import moment from "moment";
// import { getVisLevelList } from "@/api/acc";
// import { layout } from "zlink-front";

// import 'moment/locale/zh-cn';

// const { defaultFormLayout } = layout;

// const { RangePicker } = DatePicker;
// const { Option } = Select;

// const VisitRegistrationForm = ({ open, onClose, onFinish }) => {
//   const [form] = Form.useForm();
//   // const [defaultFileList, setDefaultFileList] = useState([]) // 默认头像
//   const [profilePhoto, setProfilePhoto] = useState('') // 头像数据
//   const [isRemove, setIsRemove] = useState(false) // 是否删除头像
//   const [SelectOrgPersonModalOpen, setSelectOrgPersonModalOpen] = useState(false);

//   const [visLevelList, setVisLevelList] = useState([]);
//   useEffect(() => {
//     getVisLevelList({
//       pageNumber: 1,
//       pageSize: 999999,
//       name: "",
//     }).then(res => {
//       const options = res.accessGroups.map(item => ({
//         value: item.id,
//         label: item.name,
//       }));
//       setVisLevelList(options);
//     });
//   }, []);


//   // 已选被访人
//   const [SelectedGuest, setSelectedGuest] = useState([])

//   // 设置默认访客时间值
//   const [defaultVisitTime, setDefaultVisitTime] = useState([
//     moment(), // 开始时间，默认为当前时间
//     moment().add(1, 'hour') // 结束时间，默认为当前时间往后一个小时
//   ]);


//   // 定义日期不可用函数
//   const disabledDate = (current) => {
//     return current && current < moment().subtract(1, "minute");
//   };

//   // 定义禁用时间函数
//   const disabledTime = (current, type) => {
//     // 设置默认的开始时间
//     const currentTime = defaultVisitTime[0];

//     if (type === 'start') {
//       // 禁用开始时间之前的小时和分钟
//       return {
//         disabledHours: () => current && current.isSameOrBefore(currentTime, 'day') ? [...Array(currentTime.hour()).keys()] : [],
//         disabledMinutes: (hour) => {
//           if (current && current.isSameOrBefore(currentTime, 'day') && hour === currentTime.hour()) {
//             return [...Array(currentTime.minute()).keys()];
//           }
//           return [];
//         },
//       };
//     } else if (type === 'end') {
//       // 计算开始时间加一分钟后得到的时间
//       const startPlusOneMinute = moment(currentTime).add(1, 'minute');
//       // 禁用开始时间加一分钟之前的小时和分钟
//       return {
//         disabledHours: () => current && current.isSameOrBefore(startPlusOneMinute, 'day') ? [...Array(startPlusOneMinute.hour()).keys()] : [],
//         disabledMinutes: (hour) => {
//           if (current && current.isSameOrBefore(startPlusOneMinute, 'day') && hour === startPlusOneMinute.hour()) {
//             return [...Array(startPlusOneMinute.minute()).keys()];
//           }
//           return [];
//         },
//       };
//     }
//   };

//   // 选择被访人值变化
//   const handleLeadChange = async ({ checkedNodes }) => {

//     if (checkedNodes && checkedNodes.length) {
//       form.setFieldsValue({
//         visitEmpName: checkedNodes[0].name,
//         visitEmpId: checkedNodes[0].id,
//       })
//       setSelectedGuest([checkedNodes[0]])
//     } else {
//       form.setFieldsValue({
//         visitEmpName: '',
//         visitEmpId: '',
//       })
//       setSelectedGuest([])
//       return
//     }

//   }


//   // 头像添加
//   const add = (res) => {
//     setProfilePhoto(res.file);
//     setIsRemove(false);
//     validateProfilePhoto()

//   }

//   // 头像移除
//   const remove = (isValidate = true) => {
//     setIsRemove(true);
//     setProfilePhoto('');
//     isValidate && validateProfilePhoto()

//   }
//   // 手动校验头像
//   const validateProfilePhoto = () => {
//     form.validateFields(['profilePhotoFile'])
//       .then((values) => {
//         console.log('校验通过', values);
//         // 执行其他操作
//       })
//       .catch((error) => {
//         console.log('校验失败', error);
//       });
//   }

//   const handleSubmit = () => {
//     form.validateFields()
//       .then((values) => {
//         if (isRemove) {
//           values.profilePhotoFile = ''
//         } else if (!!profilePhoto?.file) {
//           values.profilePhotoFile = profilePhoto.file
//         }
//         values.visitEmpId = form.getFieldsValue(true).visitEmpId
//         values.visitDate = `${values.visitTime[0].format('YYYY/MM/DD HH:mm')}:00`
//         values.visitEndDate = `${values.visitTime[1].format('YYYY/MM/DD HH:mm')}:59`
//         values.visitorCompanyName || delete values.visitorCompanyName

//         values.verifyMode = 3  /** 验证方式 1:密码、2:二维码、3:人脸*/
//         onFinish(values, form.resetFields);
//       })
//       .catch((err) => {

//       })
//   };



//   const handleReset = () => {
//     form.resetFields(); // 重置表单数据
//     setSelectedGuest([])
//     setDefaultVisitTime([
//       moment(), // 开始时间，默认为当前时间
//       moment().add(1, 'hour') // 结束时间，默认为当前时间往后一个小时
//     ])
//     remove(false)
//   };

//   useEffect(() => { handleReset() }, [open])

//   // 校验文件是否选择
//   const validateFile = () => {
//     if (profilePhoto === '') {
//       return Promise.reject(new Error("请上传人脸图片"));
//     }
//     return Promise.resolve();
//   };
//   return (
//     <>
//       <Drawer
//         title="来访登记"
//         open={open}
//         onClose={onClose}
//         width={510}
//         destroyOnClose
//       >
//         <Form
//           {...defaultFormLayout.form}

//           form={form} onFinish={handleSubmit} layout="Horizontal">
//           <Form.Item
//             name="visitorName"
//             label="访客姓名"
//             rules={[
//               { required: true, message: "请输入访客姓名" },
//               { max: 10, message: "访客姓名最多输入10个字" },
//             ]}
//           >
//             <Input placeholder="请输入访客姓名" />
//           </Form.Item>
//           <Form.Item
//             name="visitorPhone"
//             label="访客手机号"
//             rules={[
//               { required: true, message: '请输入访客手机号' },
//               { pattern: /^1[3456789]\d{9}$/, message: '手机号格式不正确' },
//             ]}
//           >
//             <Input placeholder="请输入访客手机号" />
//           </Form.Item>
//           <Form.Item name="visitorCompanyName" label="访客所属公司:"
//             rules={[
//               { max: 20, message: "访客所属公司最多输入20个字" },
//             ]}
//           >
//             <Input placeholder="请输入访客所属公司" />
//           </Form.Item>
//           <Form.Item
//             name="visitReason"
//             label="来访事由"
//             rules={[
//               { required: true, message: "请输入来访事由" },
//               { max: 20, message: "来访事由最多输入20个字" },
//             ]}
//           >
//             <Input.TextArea placeholder="请输入来访事由" rows={4} />
//           </Form.Item>
//           <Form.Item
//             name="visitTime"
//             label="预约时间"
//             initialValue={defaultVisitTime}
//             rules={[{ required: true, message: "请选择预约时间" }]}
//           >
//             <RangePicker
//               disabledTime={disabledTime}
//               disabledDate={disabledDate}
//               disabled={[true, false]}
//               showTime={{ format: 'HH:mm' }}
//               format="YYYY-MM-DD HH:mm"
//             />
//           </Form.Item>


//           <Form.Item
//             name="accessGroupIds"
//             label="通行权限"
//             rules={[{ required: true, message: "请选择通行权限" }]}
//           >
//             <Select placeholder="请选择通行权限">
//               {visLevelList.map((option) => (
//                 <Option key={option.value} value={option.value}>
//                   {option.label}
//                 </Option>
//               ))}
//             </Select>
//           </Form.Item>
//           <Form.Item
//             name="visitEmpName"
//             label="被访人"
//             rules={[{ required: true, message: "请选择被访人" }]}
//           >
//             <Select open={false} placeholder="请选择被访人" onClick={() => setSelectOrgPersonModalOpen(true)}>
//             </Select>
//           </Form.Item>
//           {/* 头像 */}
//           <ProFormText
//             name={["profilePhotoFile"]}
//             width="md"
//             label='录入人脸信息'
//             placeholder='请上传人脸图片'
//             rules={[{ validator: validateFile }]}
//             required={true}
//           >
//             <BaseFileUpload
//               maxFiles={1}
//               accept="image/*"
//               isUpload={false}
//               add={add}
//               remove={remove}
//               returnType="blob"
//             />
//           </ProFormText>
//           <Form.Item style={{ marginLeft: '101px' }}>
//             <Button type="ghost" onClick={handleReset} style={{ marginRight: '15px' }}>
//               重置
//             </Button>
//             <Button type="primary" htmlType="submit">
//               提交
//             </Button>
//           </Form.Item>
//         </Form>
//       </Drawer>

//       <SelectOrgPersonModal
//         ProhibitedDep={true}
//         PersonnelIDList={SelectedGuest}
//         visible={SelectOrgPersonModalOpen}
//         onChange={handleLeadChange}
//         close={() => setSelectOrgPersonModalOpen(false)}
//         mode="single"
//         title='选择被访人'
//       />
//     </>
//   );
// };

// export default VisitRegistrationForm;
import React, { useState, useEffect } from "react";
import { Drawer, Form, Input, Select, Button, DatePicker } from "antd";
import BaseFileUpload from "@/components/Basic/BaseFileUpload";
import ProForm, { ProFormText } from "@ant-design/pro-form";
import SelectOrgPersonModal from "@/components/SelectOrgPersonModal/Index";
import moment from "moment";
import { getVisLevelList } from "@/api/acc";
import { layout } from "zlink-front";
import { useTranslation } from 'react-i18next';
import 'moment/locale/zh-cn';
import withForm from "@/hoc/withForm";
const ProFormHoc = withForm(ProForm)

const { defaultFormLayout } = layout;

const { RangePicker } = DatePicker;
const { Option } = Select;

const VisitRegistrationForm = ({ open, onClose, onFinish }) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  // const [defaultFileList, setDefaultFileList] = useState([]) // 默认头像
  const [profilePhoto, setProfilePhoto] = useState('') // 头像数据
  const [isRemove, setIsRemove] = useState(false) // 是否删除头像
  const [SelectOrgPersonModalOpen, setSelectOrgPersonModalOpen] = useState(false);

  const [visLevelList, setVisLevelList] = useState([]);
  useEffect(() => {
    getVisLevelList({
      pageNumber: 1,
      pageSize: 999999,
      name: "",
    }).then(res => {
      const options = res.accessGroups.map(item => ({
        value: item.id,
        label: item.name,
      }));
      setVisLevelList(options);
    });
  }, []);


  // 已选被访人
  const [SelectedGuest, setSelectedGuest] = useState([])

  // 设置默认访客时间值
  const [defaultVisitTime, setDefaultVisitTime] = useState([
    moment(), // 开始时间，默认为当前时间
    moment().add(1, 'hour') // 结束时间，默认为当前时间往后一个小时
  ]);


  // 定义日期不可用函数
  const disabledDate = (current) => {
    return current && current < moment().subtract(1, "minute");
  };

  // 定义禁用时间函数
  const disabledTime = (current, type) => {
    // 设置默认的开始时间
    const currentTime = defaultVisitTime[0];

    if (type === 'start') {
      // 禁用开始时间之前的小时和分钟
      return {
        disabledHours: () => current && current.isSameOrBefore(currentTime, 'day') ? [...Array(currentTime.hour()).keys()] : [],
        disabledMinutes: (hour) => {
          if (current && current.isSameOrBefore(currentTime, 'day') && hour === currentTime.hour()) {
            return [...Array(currentTime.minute()).keys()];
          }
          return [];
        },
      };
    } else if (type === 'end') {
      // 计算开始时间加一分钟后得到的时间
      const startPlusOneMinute = moment(currentTime).add(1, 'minute');
      // 禁用开始时间加一分钟之前的小时和分钟
      return {
        disabledHours: () => current && current.isSameOrBefore(startPlusOneMinute, 'day') ? [...Array(startPlusOneMinute.hour()).keys()] : [],
        disabledMinutes: (hour) => {
          if (current && current.isSameOrBefore(startPlusOneMinute, 'day') && hour === startPlusOneMinute.hour()) {
            return [...Array(startPlusOneMinute.minute()).keys()];
          }
          return [];
        },
      };
    }
  };

  // 选择被访人值变化
  const handleLeadChange = async ({ checkedNodes }) => {

    if (checkedNodes && checkedNodes.length) {
      form.setFieldsValue({
        visitEmpName: checkedNodes[0].name,
        visitEmpId: checkedNodes[0].id,
      })
      setSelectedGuest([checkedNodes[0]])
    } else {
      form.setFieldsValue({
        visitEmpName: '',
        visitEmpId: '',
      })
      setSelectedGuest([])
      return
    }

  }


  // 头像添加
  const add = (res) => {
    setProfilePhoto(res.file);
    setIsRemove(false);
    validateProfilePhoto()

  }

  // 头像移除
  const remove = (isValidate = true) => {
    setIsRemove(true);
    setProfilePhoto('');
    isValidate && validateProfilePhoto()

  }
  // 手动校验头像
  const validateProfilePhoto = () => {
    form.validateFields(['profilePhotoFile'])
      .then((values) => {
        console.log('校验通过', values);
        // 执行其他操作
      })
      .catch((error) => {
        console.log('校验失败', error);
      });
  }

  const handleSubmit = () => {
    form.validateFields()
      .then((values) => {
        if (isRemove) {
          values.profilePhotoFile = ''
        } else if (!!profilePhoto?.file) {
          values.profilePhotoFile = profilePhoto.file
        }
        values.visitEmpId = form.getFieldsValue(true).visitEmpId
        values.visitDate = `${values.visitTime[0].format('YYYY/MM/DD HH:mm')}:00`
        values.visitEndDate = `${values.visitTime[1].format('YYYY/MM/DD HH:mm')}:59`
        values.visitorCompanyName || delete values.visitorCompanyName

        values.verifyMode = 3  /** 验证方式 1:密码、2:二维码、3:人脸*/
        onFinish(values, form.resetFields);
      })
      .catch((err) => {

      })
  };



  const handleReset = () => {
    form.resetFields(); // 重置表单数据
    setSelectedGuest([])
    setDefaultVisitTime([
      moment(), // 开始时间，默认为当前时间
      moment().add(1, 'hour') // 结束时间，默认为当前时间往后一个小时
    ])
    remove(false)
  };

  useEffect(() => { handleReset() }, [open])

  // 校验文件是否选择
  const validateFile = () => {
    if (profilePhoto === '') {
      return Promise.reject(new Error(t('components.VisitRegistrationForm.validationProfilePhotoFile')));
    }
    return Promise.resolve();
  };
  return (
    <>
      <Drawer
        title={t('components.VisitRegistrationForm.title')}
        open={open}
        onClose={onClose}
        width={510}
        destroyOnClose
      >
        <ProFormHoc
          isDefaultSubmitter={true}
          form={form}
          reset={handleReset}
          // submitter={false}
          onFinish={handleSubmit}
          layoutType={'defaultFormLayout'}
        >
          <Form.Item
            name="visitorName"
            label={t('components.VisitRegistrationForm.visitorName')}
            rules={[
              { required: true, message: t('components.VisitRegistrationForm.visitorNameRequired') },
              { max: 10, message: t('components.VisitRegistrationForm.visitorNameMaxLength') },
            ]}
          >
            <Input placeholder={t('components.VisitRegistrationForm.visitorNamePlaceholder')} />
          </Form.Item>
          <Form.Item
            name="visitorPhone"
            label={t('components.VisitRegistrationForm.visitorPhone')}
            rules={[
              { required: true, message: t('components.VisitRegistrationForm.visitorPhoneRequired') },
              { pattern: /^1[3456789]\d{9}$/, message: t('components.VisitRegistrationForm.visitorPhonePattern') },
            ]}
          >
            <Input placeholder={t('components.VisitRegistrationForm.visitorPhonePlaceholder')} />
          </Form.Item>
          <Form.Item name="visitorCompanyName" label={t('components.VisitRegistrationForm.visitorCompanyName')}
            rules={[
              { max: 20, message: t('components.VisitRegistrationForm.visitorCompanyNameMaxLength') },
            ]}
          >
            <Input placeholder={t('components.VisitRegistrationForm.visitorCompanyNamePlaceholder')} />
          </Form.Item>
          <Form.Item
            name="visitReason"
            label={t('components.VisitRegistrationForm.visitReason')}
            rules={[
              { required: true, message: t('components.VisitRegistrationForm.visitReasonRequired') },
              { max: 20, message: t('components.VisitRegistrationForm.visitReasonMaxLength') },
            ]}
          >
            <Input.TextArea placeholder={t('components.VisitRegistrationForm.visitReasonPlaceholder')} rows={4} />
          </Form.Item>
          <Form.Item
            name="visitTime"
            label={t('components.VisitRegistrationForm.visitTime')}
            initialValue={defaultVisitTime}
            rules={[{ required: true, message: t('components.VisitRegistrationForm.visitTimeRequired') }]}
          >
            <RangePicker
              disabledTime={disabledTime}
              disabledDate={disabledDate}
              disabled={[true, false]}
              showTime={{ format: 'HH:mm' }}
              format="YYYY-MM-DD HH:mm"
            />
          </Form.Item>


          <Form.Item
            name="accessGroupIds"
            label={t('components.VisitRegistrationForm.accessGroupIds')}
            rules={[{ required: true, message: t('components.VisitRegistrationForm.accessGroupIdsRequired') }]}
          >
            <Select placeholder={t('components.VisitRegistrationForm.accessGroupIds')}>
              {visLevelList.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="visitEmpName"
            label={t('components.VisitRegistrationForm.visitEmpName')}
            rules={[{ required: true, message: t('components.VisitRegistrationForm.visitEmpNameRequired') }]}
          >
            <Select open={false} placeholder={t('components.VisitRegistrationForm.visitEmpNamePlaceholder')} onClick={() => setSelectOrgPersonModalOpen(true)}>
            </Select>
          </Form.Item>
          {/* 头像 */}
          <ProFormText
            name={["profilePhotoFile"]}
            width="md"
            label={t('components.VisitRegistrationForm.profilePhotoFile')}
            placeholder={t('components.VisitRegistrationForm.profilePhotoFilePlaceholder')}
            rules={[{ validator: validateFile }]}
            required={true}
          >
            <BaseFileUpload
              maxFiles={1}
              accept="image/*"
              isUpload={false}
              add={add}
              remove={remove}
              returnType="blob"
            />
          </ProFormText>
          {/* <Form.Item style={{ marginLeft: '101px' }}>
            <Button type="ghost" onClick={handleReset} style={{ marginRight: '15px' }}>
              {t('components.VisitRegistrationForm.reset')}
            </Button>
            <Button type="primary" htmlType="submit">
              {t('components.VisitRegistrationForm.submit')}
            </Button>
          </Form.Item> */}
        </ProFormHoc>
      </Drawer>

      <SelectOrgPersonModal
        ProhibitedDep={true}
        PersonnelIDList={SelectedGuest}
        visible={SelectOrgPersonModalOpen}
        onChange={handleLeadChange}
        close={() => setSelectOrgPersonModalOpen(false)}
        mode="single"
        title={t('components.VisitRegistrationForm.selectVisiteeTitle')}
      />
    </>
  );
};

export default VisitRegistrationForm;
