import React, { useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { ProTableCustom } from "zlink-front";
import "@/assets/scss/vis.scss";
import useHorizontalScroll from '../../../hook/useHorizontalScroll';
import VisitorDetailsDrawer from '@/components/VisitorDetailsDrawer';
import moment from 'moment';
import { getVisitorArrivalRecord } from "@/api/vis";
import DateTimeFormat from "@/utils/DateTimeFormat";

export default function Index() {
  const { t } = useTranslation();
  const actionRef = useRef();
  const [open, setOpen] = useState(false);
  useHorizontalScroll('ant-table-body', 300);
  const [selectedRow, setSelectedRow] = useState({});

  const columns = [
    {
      title: t("vis.VisitorArrivalRecord.visitorName"),
      key: "visitorName",
      dataIndex: "visitorName",
      hideInSearch: true,
      fixed: 'left',
      width: 150,
      render: (_, r) => r.visitorName || '-'
    },
    {
      title: t("vis.VisitorArrivalRecord.visitorPhone"),
      key: "visitorPhone",
      dataIndex: "visitorPhone",
      hideInSearch: false,
      fixed: 'left',
      width: 120,
      render: (_, r) => {
        const phone = r.visitorPhone.replace('+86-', '');
        return phone;
      }
    },
    {
      title: t("vis.VisitorArrivalRecord.visitorCompanyName"),
      key: "visitorCompanyName",
      dataIndex: "visitorCompanyName",
      hideInSearch: false,
      width: 200,
      render: (_, r) => r.visitorCompanyName || '-'
    },
    {
      title: t("vis.VisitorArrivalRecord.visitDate"),
      key: "visitDate",
      dataIndex: "visitDate",
      hideInSearch: false,
      width: 200,
      render: (_, r) => <DateTimeFormat date={r.visitDate} format='minute' />
    },
    {
      title: t("vis.VisitorArrivalRecord.visitEndDate"),
      key: "visitEndDate",
      dataIndex: "visitEndDate",
      hideInSearch: false,
      width: 200,
      render: (_, r) => <DateTimeFormat date={r.visitEndDate} format='minute' />

    },
    {
      title: t("vis.VisitorArrivalRecord.visitEmpName"),
      key: "visitEmpName",
      dataIndex: "visitEmpName",
      hideInSearch: false,
      width: 200,
      render: (_, r) => r.visitEmpName || '-'
    },
    {
      title: t("vis.VisitorArrivalRecord.checkStatus"),
      key: "checkStatus",
      dataIndex: "checkStatus",
      hideInSearch: false,
      width: 200,
      render: (_, r) => {
        const checkStatusMap = {
          1: t('vis.VisitorArrivalRecord.futureVisit'),
          2: t('vis.VisitorArrivalRecord.visited'),
          3: t('vis.VisitorArrivalRecord.signedOut'),
          4: t('vis.VisitorArrivalRecord.invalid'),
        }
        return checkStatusMap[r.checkStatus] || '-'
      }
    },
    {
      title: t("vis.VisitorArrivalRecord.visitReason"),
      key: "visitReason",
      dataIndex: "visitReason",
      hideInSearch: false,
      width: 300,
      render: (_, r) => r.visitReason || '-'
    },
    {
      title: t("common.table.option"),
      key: "operation",
      dataIndex: "operation",
      hideInSearch: false,
      fixed: 'right',
      width: 100,
      render: (_, record) => {
        return <Button type="link" onClick={() => handleOpenDetails(record)}>
          {t('vis.VisitorArrivalRecord.passingRecords')}
        </Button>
      }
    },
  ];

  const handleOpenDetails = (value) => {
    const record = { ...value }
    const verifyModeObj = {
      1: t('vis.VisitorArrivalRecord.password'),
      2: t('vis.VisitorArrivalRecord.qrCode'),
      3: t('vis.VisitorArrivalRecord.facePhoto'),
    }
    record.verify = verifyModeObj[record.verifyMode]

    record.checkInDate = DateTimeFormat({ date: record.checkInDate, format: "minute" })
    record.checkOutDate = DateTimeFormat({ date: record.checkOutDate, format: "minute" })
    record.visitEndDate = DateTimeFormat({ date: record.visitEndDate, format: "minute" })
    record.visitDate = DateTimeFormat({ date: record.visitDate, format: "minute" })

    setSelectedRow(record);
    setOpen(true);
  };

  const columnsWithEllipsis = columns.map(column => {
    const { render, ...rest } = column;
    const key = column.dataIndex;
    return {
      ...rest,
      ellipsis: true,
      render: (_, record) => {
        const text = record[key];
        if (render) return render(_, record);
        if (!render) return <span title={text}>{text}</span>;
      }
    };
  });

  const items = [
    {
      key: 'visitorName',
      label: t('vis.VisitorArrivalRecord.visitorName'),
    },
    {
      key: 'visitDate',
      label: t('vis.VisitorArrivalRecord.visitDate'),
    },
    {
      key: 'visitEndDate',
      label: t('vis.VisitorArrivalRecord.visitEndDate'),
    },
    {
      key: 'passingRecords',
      label: t('vis.VisitorArrivalRecord.passingRecords'),
      type: 'list'
    },
  ];

  return (
    <div className='vis-invitation-list'>
      <ProTableCustom
        columns={columnsWithEllipsis}
        actionRef={actionRef}
        request={async ({ current: pageNumber, pageSize, ...restParams }, sort, filter) => {
          const res = await getVisitorArrivalRecord({
            pageSize: pageSize,
            pageNumber: pageNumber,
            visitorName: restParams?.searchParams || "",
          });
          return {
            data: res.visRegistrationList,
            success: true,
            total: res.totalCount,
          };
        }}
        searchPlaceholder={t("vis.VisitorArrivalRecord.searchPlaceholder")}
        rowKey="id"
        toolBar={[]}
      />
      {open && <VisitorDetailsDrawer
        width={400}
        items={items}
        open={open}
        data={selectedRow}
        title={t('common.table.detail')}
        onClose={() => { setOpen(false); }}
      />}
    </div>
  )
}
