import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Menu, Space, Layout } from "antd";
import navBtn from "@/assets/images/ic_side_hide@2x.png";
import { nanoid } from "nanoid";
const { Sider } = Layout;

const MenuCustom = (props) => {
  const { path, language, routeList } = props
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [items, setItems] = useState([]);
  const handleClick = (menu) => {
    const data = items.find(item => item.key === menu?.key)
    navigate(data.path, { state: { pathName: data.name } });
  };
  const [keyVlaue, setKeyVlaue] = useState("");
  useEffect(() => {
    //当前选择菜单
    setKeyVlaue(path?.meta?.parent || path?.meta?.i18nKey);
  }, [path?.meta?.i18nKey]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    //菜单
    const children = routeList?.children
    if (!!children && !!children.length) {
      const menus = []
      children.forEach((item) => {
        // 菜单权限code
        const code = item.meta?.roleCode
        const codeStatus = !code || (!!code && props?.permissions?.includes(code))
        // 游客（账号未绑定企业）
        const touristStatus = !!props.orgInfo?.id ? true : (!!item.meta?.tourist && !props.orgInfo?.id)
        if (!!codeStatus && !!touristStatus && !item?.hidden) {
          menus.push({
            ...item,
            key: item?.meta?.i18nKey || item?.name,
            icon: item?.meta?.icon,
            label: t(item?.meta?.i18nKey || item?.name)
          })
        }
      })
      setItems(menus)
    } else {
      setItems([])
    }
  }, [routeList?.children, language]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        onCollapse={() => setCollapsed(!collapsed)}
        theme="light"
        width={i18n.language === 'zh-CN' ? 208 : 290}
      >
        {routeList?.children.length && path && (
          <div className="main-custom__menu">
            <div
              className={
                "main-custom__menu-title " + (!!collapsed ? "center" : "")
              }
            >
              <Space key={nanoid()}>
                {routeList.meta?.icon}
                {!collapsed && <span>{t(routeList?.meta.i18nKey)}</span>}
              </Space>
            </div>
            <Menu
              mode="inline"
              selectedKeys={[keyVlaue]}
              items={items}
              onClick={(item) => handleClick(item)}
            />
            <div
              className="main-custom__menu-footer"
              onClick={() => setCollapsed(!collapsed)}
            >
              <img
                src={navBtn}
                className={collapsed ? "hide" : "show"}
                alt=""
              />
            </div>
          </div>
        )}
      </Sider>
    </>
  );
}

const mapStateToProps = ({ account, settings }) => ({
  orgInfo: account.orgInfo,
  permissions: account.permissions,
  language: settings.language
})

export default connect(
  mapStateToProps
)(MenuCustom)
