
import { batchDownload } from "@/api/sys";
import store from "@/store/store";

export const patt = /src=['"]([^'"]+)['"]+/g // 
export const regexp = /[^>]*src=['"]([^'"]+)[^>]*>/gi
export const testDepName = /^[A-Za-z0-9\u4E00-\u9FA5]+$/

// 获取富文本src中key的数组
export const getFileUrl = async (content) => {
  let temp, fileKey = []
  while ((temp = patt.exec(content)) !== null) {
    fileKey.push(temp[1])
  }
  if (fileKey.length > 0) {
    try {
      const res = await batchDownload({ objectKeys: fileKey })
      const arr = []
      res.forEach(item => {
        arr.push({
          key: item?.objectKey,
          url: item?.preSignedUrl
        })
      })
      return arr
    } catch (e) {
      console.log(e)
    }
  }
}

const replaceStyle = (match) => {
  const style = match.split('style="')
  return style.length === 2 ? `style="${style[1]}` : '/>'
}

// 替换src
export const replaceFileUrl = (content, fileUrl) => {
  const data = content.replace(regexp, function (match, key) {
    const item = fileUrl?.find(item => item.key === key)
    let str = ''
    if (!!item) {
      if (match.indexOf('<img') !== -1) {
        str = `<img src="${item.url}" ${replaceStyle(match)}`
      } else if (match.indexOf('<source') !== -1) {
        str = `<source src="${item.url}"/>`
      }
    }
    return str
  })
  return data
}

export const replaceContent = async (content) => {
  const fileUrl = await getFileUrl(content)
  if (!!fileUrl && fileUrl.length) {
    return replaceFileUrl(content, fileUrl)
  } else {
    return content
  }
}

// 处理权限状态
export const getPermissionsStatus = (code) => {
  const permissions = store.getState()?.account?.permissions
  return permissions?.includes(code) || false
}

export const deWeight = (arr) => {
  for (var i = 0; i < arr.length - 1; i++) {
    for (var j = i + 1; j < arr.length; j++) {
      if (arr[i].id === arr[j].id) {
        arr.splice(j, 1);
        //因为数组长度减小1，所以直接 j++ 会漏掉一个元素，所以要 j--
        j--;
      }
    }
  }
  return arr;
}

/**
 * 给树结构增加层级
 *
 * @param {*} data
 * @param {number} [level = 0]  层级从0开始
 * @return {*} 增加层级后的数据
 */
export const addLevelCode = (data, level = 0) => {
  data.forEach((item) => {
    item.levelCode = level;
    if (item.children) {
      addLevelCode(item.children, level + 1);
    }
  });
  return data;
}

/**
 * 获取树结构最深层级数
 *
 * @param {*} data
 * @param {number} [level = 1]  层级从1开始
 * @param {number} [maxLevel = 1]  最大层级 默认1
 * @return {number} 最深层级数
 */
export const getMaxLevel = (data, level = 1, maxLevel = 1) => {
  data.forEach(item => {
    item.level = level
    if (level > maxLevel) {
      maxLevel = level
    }
    if (!!item.children && item.children.length > 0) {
      maxLevel = getMaxLevel(item.children, level + 1, maxLevel)
    }
  })
  return maxLevel
}


/**
 * 树结构转list
 *
 * @param {*} data
 * @param {tree} [tree]  树结构数据
 * @return {Array} 列表数据
 */
export const treeToList = (tree) => {
  // 定义空数组 -方法所有的tree中的元素
  let resArr = []
  // 定义了数组，放了tree里面的 第一层元素
  let queue = [...tree]
  // 循环--条件  queue 数组的长度不为0 就一直循环
  while (queue.length) {
    // 每次删除queue中第一个元素
    let v = queue.shift()
    // 如果可以取出来就把他添加到resArr
    if (v) {
      const { children, ...data } = v
      resArr.push(data);
    }
    // 判断v是否有孩子
    if (v && v.children) {
      queue.push(...v.children)
    }
  }
  return resArr
}



/**
 * 判断是否是有纯空格（纯空格需要返回空字符串）
 *
 * @param {*} data
 * @param {string} value  传入参数
 * @return {string}  newValue 
 */
export const handleSpaces = (value) => {
  return value.replace(/\s/g, '').length > 0 ? value : ''
}

/**
 * 当前页面最后一页无数据情况下 判断是否返回上一页）
 *
 * @param {ref} actionRef
 * @param {number} len  数据长度
 * @param {number} totalCount  总条数
 * @return {boolean}  是否需要重新加载 
 */
export const hanldeTablePrePage = (actionRef, len, totalCount) => {
  const { pageInfo, setPageInfo } = actionRef?.current
  if (!len && totalCount > 0 && pageInfo.current > 1) {
    setPageInfo({
      ...pageInfo,
      current: pageInfo.current - 1
    })
    return true
  }
  return false
}

/**
 * 计算最后一页的有几条数据
 *
 * @param {number} totalCount - 总共的条目数量
 * @param {number} pageSize - 每页显示的条目数量
 * @param {number} totalPages - 总页数
 * @returns {number} - 最后一页的条目数量
 */
export const calculateLastPageCount = (totalCount, pageSize, totalPages) => {
  // 计算余数，即除完以后剩下的数量
  const remainder = totalCount % pageSize;

  // 如果总共只有一页，则直接返回余数（如果余数为 0 则返回每页数量）
  if (totalPages === 1) {
    return remainder === 0 ? pageSize : remainder;
  } else {
    // 如果不止一页，则最后一页的数量就是余数（如果余数为 0 则返回每页数量）
    return remainder === 0 ? pageSize : remainder;
  }
}

/**
 * 从给定的获取函数中获取所有分页数据。
 *
 * 这个函数处理从分页 API 获取数据，将多个页面的结果合并成一个数组。
 *
 * @param {Function} fetchFunction - 用于获取数据的函数。它应该接受一个参数对象并返回一个 Promise。
 * @param {Object} options - 配置获取过程的选项。
 * @param {Object} [options.params={}] - 请求的初始参数。
 * @param {number} [options.pageSize=10] - 每页的数据项数量。
 * @param {string} [options.pageParamName='pageNumber'] - 请求中用于表示页码的参数名称。
 * @param {string} [options.totalCountParamName='totalCount'] - 响应中包含总数据量的字段名称。
 * @param {string} [options.dataParamName='documents'] - 响应中包含数据项数组的字段名称。
 * @param {number} [options.initialPage=1] - 开始获取数据的初始页码。
 * @returns {Promise<Array>} 一个 Promise，解析为包含所有获取到的数据项的数组。
 */
export const fetchAllPaginatedData = async (fetchFunction, {
  params = {},
  pageSize = 10,
  pageParamName = 'pageNumber',
  totalCountParamName = 'totalCount',
  dataParamName = 'documents',
  initialPage = 1
} = {}) => {
  let currentPage = initialPage;
  let totalCount = 0;
  let allDocuments = [];

  // 获取初始数据
  const initialParams = { ...params, [pageParamName]: currentPage, pageSize };
  const initialRes = await fetchFunction(initialParams);

  totalCount = initialRes[totalCountParamName] || 0;
  allDocuments = initialRes[dataParamName] || [];

  // 计算总页数
  const totalPages = Math.ceil(totalCount / pageSize);

  // 如果需要，获取更多页面的数据
  while (currentPage < totalPages) {
    currentPage += 1;

    const newParams = { ...params, [pageParamName]: currentPage, pageSize };
    const res = await fetchFunction(newParams);

    allDocuments = allDocuments.concat(res[dataParamName] || []);
  }

  return allDocuments;
};



// 合并
export const deepObjectMerge = (target, ...objects) => {
  target = target || {};
  let len = objects.length;
  for (let i = 0; i < len; i++) {
    let SecondOBJ = objects[i];
    for (var key in SecondOBJ) {
      target[key] = target[key] && target[key].toString() === "[object Object]" ?
        deepObjectMerge(target[key], SecondOBJ[key]) : (target[key] = SecondOBJ[key]);
    }
  }
  return target;
};

