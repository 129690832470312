import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import MenuCustom from "./components/Layouts/MenuCustom";
import BreadcrumbsCustom from "./components/Layouts/BreadcrumbsCustom";
import { routeData, innerRoutes } from "./router/modules";
import {
  Routes,
  Route,
  Navigate,
  useLocation
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { changeAntdTheme } from 'mini-dynamic-antd-theme';
import { auth } from "zlink-front";

const App = props => {
  const { t, i18n } = useTranslation()
  const [routes] = useState(routeData())
  const [routeList, setRouteList] = useState({})
  const [path, setPath] = useState('')
  const location = useLocation()

  useEffect(() => {
    const updateTitleAndFavicon = () => {
      const pathSegments = location.pathname.split('/');
      // const activeSegment = pathSegments[1] || '';
      const isEnvCn = process?.env?.REACT_APP_LANGUAGE === 'zh-CN';

      const titleSuffix = isEnvCn ? '熵基互联' : 'ZKBio Zlink';

      // 查找当前路由对应的 i18n key
      const i18nName = innerRoutes[0]?.children?.find(item => item.path.split('/')[2] === pathSegments[2]);
      const i18nKey = i18nName?.meta?.i18nKey;

      // 设置页面标题
      document.title = i18nKey ? `${t(i18nKey)} - ${titleSuffix}` : titleSuffix;

      // 设置 favicon
      const faviconElement = document.querySelector('link[rel="icon"]');
      if (faviconElement) {
        try {
          const iconPath = isEnvCn
            ? require('@/assets/images/favicon.ico').default
            : require('@/assets/images/favicon-en.ico').default;
          faviconElement.href = iconPath;
        } catch (error) {
          console.error('Error loading favicon:', error);
        }
      } else {
        console.warn('Favicon element not found.');
      }
    };

    const updateRouteListAndPath = () => {
      const pathSegments = location.pathname.split('/');
      const activeSegment = pathSegments[1] || '';
      const idx = innerRoutes.findIndex(item => item.name === activeSegment);

      if (idx !== -1) {
        setRouteList(innerRoutes[idx] || {});
        const activeChild = innerRoutes[idx]?.children?.find(item => item.path === location.pathname);
        if (activeChild) {
          setPath(activeChild);
        }
      } else {
        setRouteList({});
      }
    };

    let id = setTimeout(() => {
      updateTitleAndFavicon();
      updateRouteListAndPath();
    }, 0);

    return () => clearTimeout(id);
  }, [location, innerRoutes, t]); // 添加必要的依赖项

  // useEffect(() => {
  //   const pathVal = (location.pathname.split('/'))
  //   const active = pathVal[1] || ''
  //   const idx = innerRoutes.findIndex(item => item.name === active)
  //   // window.document.title = ((!!pathVal[1] && pathVal[1] !== 'zkbio_att') ?
  //   //   t('router.' + pathVal[pathVal.length - 1]) : t('common.Att')) + '-' + t('common.zlink')
  //   const isEnvCn = process?.env?.REACT_APP_LANGUAGE === 'zh-CN';
  //   const i18nName = innerRoutes[0].children?.find(item => {
  //     return item.path.split('/')[2] === pathVal[2]
  //   })
  //   const faviconElement = document.querySelector('link[rel="icon"]');

  //   // 设置页面标题
  //   const titleSuffix = isEnvCn ? '熵基互联' : 'ZKBio Zlink';
  //   const i18nKey = i18nName?.meta?.i18nKey;
  //   window.document.title = i18nKey ? `${t(i18nKey)} - ${titleSuffix}` : titleSuffix;

  //   // 设置 favicon
  //   if (faviconElement) {
  //     try {
  //       const iconPath = isEnvCn
  //         ? require('@/assets/images/favicon.ico').default
  //         : require('@/assets/images/favicon-en.ico').default;

  //       faviconElement.href = iconPath;
  //     } catch (error) {
  //       console.error('Error loading favicon:', error);
  //     }
  //   } else {
  //     console.warn('Favicon element not found.');
  //   }


  //   if (idx !== -1) {
  //     setRouteList(innerRoutes[idx] || {})
  //     if (innerRoutes[idx]?.children && innerRoutes[idx]?.children.length) {
  //       const activeIdx = innerRoutes[idx]?.children.findIndex(item => item.path === location.pathname)
  //       if (activeIdx !== -1) {
  //         setPath(innerRoutes[idx]?.children[activeIdx])
  //       }
  //     }
  //   } else {
  //     setRouteList({})
  //   }
  // }, [location]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    changeAntdTheme(auth.getAntdPrimaryColor() || '#7AC143')
    i18n.changeLanguage(auth.getLanguage() || 'zh-CN');
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    props?.language && i18n.changeLanguage(props?.language);
  }, [props?.language])// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="app-container">
        <div className="main-custom">
          {
            !routeList?.childrenHidden && !!routeList?.children &&
            !!routeList?.children.length && routeList.name !== 'work' &&
            <MenuCustom path={path} routeList={routeList} />
          }
          <div className={`main-custom__center ${!routeList?.meta?.hideBreadcrumbs && 'zk-pd20'}`}>
            {
              !!routeList?.children && !!routeList?.children.length &&
              !routeList?.meta?.hideBreadcrumbs &&
              <BreadcrumbsCustom path={path} routeList={routeList} />
            }
            <Routes>
              {
                routes.map(item => {
                  // 菜单权限code
                  const code = item.meta?.roleCode
                  const codeStatus = !!code ? props?.permissions?.includes(code) : true
                  return !!codeStatus &&
                    <Route
                      key={item.path}
                      path={item.path}
                      element={item?.element}
                    // element={item?.element}
                    />
                })
              }
              <Route path='/*' element={<Navigate to={"/notFound"} />} />
            </Routes>
          </div>
        </div>
      </div>
    </>
  )
}
const mapStateToProps = ({ account, settings }) => ({
  accountInfo: account.accountInfo,
  token: account.token,
  permissions: account.permissions,
  orgInfo: account.orgInfo,
  language: settings.language
})

export default connect(
  mapStateToProps
)(App)