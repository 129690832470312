
import React from 'react';
import { useTranslation } from "react-i18next";
import { Space, Row, Col } from "antd";
import { nanoid } from "nanoid";
import { formLayout } from "zlink-front";
const { getFormLayoutLast } = formLayout

/**
 * @param {string} layoutType    // 表单使用layout类型
 * @param {Boolean} isDefaultSubmitter  // 是否使用默认提交按钮
 */

const withForm = (WrappedComponent) => {
  const HOC = (props) => {
    const { layoutType, isDefaultSubmitter, submitter } = props
    const { t, i18n } = useTranslation()
    const formLayout = getFormLayoutLast(layoutType, i18n.language === 'zh-CN' ? 'horizontal' : 'vertical')

    return <WrappedComponent
      {...formLayout.form}
      submitter={isDefaultSubmitter && !submitter ?
        {
          searchConfig: { submitText: t('common.button.save') },
          render: (_, doms) => {
            return (
              <Row>
                <Col {...formLayout.button} >
                  <Space key={nanoid()}>{doms}</Space>
                </Col>
              </Row>
            );
          }
        } : submitter
      }
      {...props}
    />
  };
  return HOC;
}
export default withForm