import React, { useState, useEffect } from "react";
import { Select, Form, Input } from 'antd';
import { rules } from "zlink-front";
import { useTranslation } from "react-i18next";
import { country } from "@/enums/country";
const { validateMobile } = rules
const { Option } = Select

/** 国家码手机输入框配置
 * 
 * @param {Function} handlePhoneCode 回调国家码
 * @param {String} name 表单字段
 * @param {String} placeholder 输入框默认提示
 * @param {Boolean} icon 是否需要图标，登录相关模块需求
 * @param {Boolean} disabled 输入框是否禁用
 * @param {Array} rules 校验规则
 * @param {Validate} validateMobileRule 业务校验规则
 * @param {String} label 表单标签
 * @param {String} phone 默认手机号
 * @param {Boolean} md 表单宽度
 * @param {Object} labelCol 名称区域
 * @param {Object} wrapperCol 控件区域
 * 
 */

const BasePhoneCodeSelect = (props) => {
  const { handlePhoneCode, name, placeholder, icon, disabled, validateMobileRule, label, phone, md, rules,
    labelCol, wrapperCol
  } = props
  const { t } = useTranslation()
  const [isPhone, setIsPhone] = useState(false)
  const [countryData, setCountryData] = useState([])
  const handleInput = (e) => {
    const value = getValueFromEvent(e)
    validateMobileFn(value)
  }

  const validateMobileFn = (value) => {
    const status = !validateMobile(value)
    setIsPhone(status)
    handlePhoneCode(status ? '+86-' : '')
  }

  useEffect(() => {
    const data = country.filter(item => item?.status === 'assigned')
    setCountryData(data)
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    !!phone && validateMobileFn(phone)
  }, [phone])// eslint-disable-line react-hooks/exhaustive-deps

  const getValueFromEvent = (e) => {
    return e.target.value.replace(/\s*/g, "");
  }

  const defaultRules = [
    { required: true, message: t('common.form.noEmpty') },
    { validator: validateMobileRule }
  ]
  return (
    <>
      <div className={`zk-phone-input ${label && 'hasLabel'} ${isPhone && 'pl130'} `}>
        <Form.Item
          name={name}
          rules={!disabled && (rules ?? defaultRules)}
          label={label}
          getValueFromEvent={getValueFromEvent}
          labelCol={labelCol || { span: 6 }}
          wrapperCol={wrapperCol || { span: 18 }}
        >
          <Input
            disabled={disabled}
            placeholder={placeholder}
            size={!!icon ? 'large' : 'default'}
            onChange={handleInput}
            className={md && 'pro-field-md'}
          />
        </Form.Item>
        {!!icon && <div className="zk-phone-input__icon">
          <i className="iconfont zlink-ic_sign_phone" />
        </div>
        }
        {isPhone && countryData.length &&
          <Select
            disabled
            defaultValue="+86"
            dropdownStyle={{ minWidth: '140px' }}
          >
            {countryData.length && countryData.map((item, idx) =>
              <Option value="item?.countryCallingCodes" key={idx}>{`${item.countryCallingCodes} ${item.alpha2}`}</Option>
            )}
          </Select>
        }
      </div>
    </>
  )
}

export default BasePhoneCodeSelect;
