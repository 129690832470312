import React, { useRef, useState } from 'react'
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { ProTableCustom } from "zlink-front";
import "@/assets/scss/vis.scss";
import useHorizontalScroll from '../../../hook/useHorizontalScroll'
import VisitorDetailsDrawer from '@/components/VisitorDetailsDrawer'
import { getAllVisLevelList } from "@/api/acc";
import { queryVisitorInvitationList } from "@/api/vis";
import moment from 'moment';
import DateTimeFormat from "@/utils/DateTimeFormat";

export default function Index() {
  const { t } = useTranslation();
  const actionRef = useRef();
  const [open, setOpen] = useState(false);
  useHorizontalScroll('ant-table-body', 300);
  const [selectedRow, setSelectedRow] = useState({});

  const columns = [
    {
      title: t("vis.VisitorInvitationList.visitorName"),
      key: "visitorName",
      dataIndex: "visitorName",
      hideInSearch: true,
      fixed: 'left',
      width: 150,
      render: (_, r) => r.visitorName || '-'
    },
    {
      title: t("vis.VisitorInvitationList.visitorPhone"),
      key: "visitorPhone",
      dataIndex: "visitorPhone",
      hideInSearch: false,
      fixed: 'left',
      width: 120,
      render: (_, r) => {
        const phone = r.visitorPhone.replace('+86-', '');
        return phone;
      }
    },
    {
      title: t("vis.VisitorInvitationList.visitorCompanyName"),
      key: "visitorCompanyName",
      dataIndex: "visitorCompanyName",
      hideInSearch: false,
      width: 200,
      render: (_, r) => r.visitorCompanyName || '-'
    },
    {
      title: t("vis.VisitorInvitationList.visitDate"),
      key: "visitDate",
      dataIndex: "visitDate",
      hideInSearch: false,
      width: 200,
      render: (_, r) => <DateTimeFormat date={r.visitDate} format='minute' />

    },
    {
      title: t("vis.VisitorInvitationList.visitEndDate"),
      key: "visitEndDate",
      dataIndex: "visitEndDate",
      hideInSearch: false,
      width: 200,
      render: (_, r) => <DateTimeFormat date={r.visitEndDate} format='minute' />
    },
    {
      title: t("vis.VisitorInvitationList.visitEmpName"),
      key: "visitEmpName",
      dataIndex: "visitEmpName",
      hideInSearch: false,
      width: 200
    },
    {
      title: t("vis.VisitorInvitationList.reservationStatus"),
      key: "reservationStatus",
      dataIndex: "reservationStatus",
      hideInSearch: false,
      width: 200,
      render: (_, r) => {
        const checkStatusMap = {
          1: t("vis.VisitorInvitationList.pendingAcceptance"),
          2: t("vis.VisitorInvitationList.futureVisit"),
          3: t("vis.VisitorInvitationList.rejected"),
          4: t("vis.VisitorInvitationList.visited"),
          5: t("vis.VisitorInvitationList.canceled"),
          6: t("vis.VisitorInvitationList.signedOut"),
          7: t("vis.VisitorInvitationList.expired"),
        }
        return checkStatusMap[r.reservationStatus] || '-'
      }
    },
    {
      title: t("vis.VisitorInvitationList.visitReason"),
      key: "visitReason",
      dataIndex: "visitReason",
      hideInSearch: false,
      width: 300,
      render: (_, r) => r.visitReason || '-'
    },
    {
      title: t("vis.VisitorInvitationList.operation"),
      key: "operation",
      dataIndex: "operation",
      hideInSearch: false,
      fixed: 'right',
      width: '100px',
      render: (_, record) => {
        return <Button type="link" onClick={() => handleOpenDetails(record)}>
          {t('vis.VisitorInvitationList.viewDetails')}
        </Button>
      }
    },
  ];

  const handleOpenDetails = (value) => {
    const record = { ...value }
    const verifyModeObj = {
      1: t('vis.VisitorInvitationList.verifyPassword'),
      2: t('vis.VisitorInvitationList.verifyQRCode'),
      3: t('vis.VisitorInvitationList.verifyFace'),
    }
    record.verify = verifyModeObj[record.verifyMode]

    record.checkInDate = DateTimeFormat({ date: record.checkInDate, format: "minute" })
    record.checkOutDate = DateTimeFormat({ date: record.checkOutDate, format: "minute" })
    record.visitEndDate = DateTimeFormat({ date: record.visitEndDate, format: "minute" })
    record.visitDate = DateTimeFormat({ date: record.visitDate, format: "minute" })
    setSelectedRow(record);
    setOpen(true);
  };

  const columnsWithEllipsis = columns.map(column => {
    const { render, ...rest } = column;
    const key = column.dataIndex
    return {
      ...rest,
      ellipsis: true,
      render: (_, record) => {
        const text = record[key]
        if (render) return render(_, record)
        if (!render) return <span title={text}>{text}</span>
      }
    };
  });

  return (
    <div className='vis-invitation-list'>
      <ProTableCustom
        columns={columnsWithEllipsis}
        actionRef={actionRef}
        request={async ({ current: pageNumber, pageSize, ...restParams }, sort, filter) => {
          const res = await queryVisitorInvitationList({
            pageSize: pageSize,
            pageNumber: pageNumber,
            visitorName: restParams?.searchParams || "",
          });
          let accessGroups = await getAllVisLevelList({
            pageNumber: 1,
            name: "",
          }) || []
          const options = accessGroups.map(item => ({
            value: item.id,
            label: item.name,
          }));
          let data = res.visReservationList.map((e) => {
            options.forEach((o) => {
              if (e.accessGroupIds === o.value) {
                e.accessGroupName = o.label
              }
            });
            return e
          }) || []
          return {
            data: data,
            success: true,
            total: res.totalCount,
          };
        }}
        searchPlaceholder={t("vis.VisitorInvitationList.searchPlaceholder")}
        rowKey="id"
        toolBar={[]}
      />
      <VisitorDetailsDrawer
        width='500px'
        open={open}
        data={selectedRow}
        title={t('common.table.detail')}
        onClose={() => { setOpen(false); }}
      />
    </div>
  )
}
