// import React, { useRef, useState } from 'react'
// import { useTranslation } from "react-i18next";
// import { Button, Modal, message } from "antd";
// import { ProTableCustom } from "zlink-front";
// import "@/assets/scss/vis.PermissionGroupManagement.scss";
// import useHorizontalScroll from '../../../hook/useHorizontalScroll'
// import PermissionGroupEditing from './components/PermissionGroupEditing'
// import { PlusOutlined } from "@ant-design/icons";
// import { addVisLevel, getVisLevelList, updateVisLevel, deleteVisLevel, addDoor, getDoorList, deleteDoor } from "@/api/acc";
// import { getPermissionsStatus } from "@/utils/index";

// import { calculateLastPageCount } from '@/utils/index'

// const { confirm } = Modal;


// export default function index() {
//   // eslint-disable-next-line react-hooks/rules-of-hooks
//   const { t } = useTranslation();
//   // eslint-disable-next-line react-hooks/rules-of-hooks
//   const actionRef = useRef();
//   // eslint-disable-next-line react-hooks/rules-of-hooks
//   const [open, setOpen] = useState(false);
//   // eslint-disable-next-line react-hooks/rules-of-hooks
//   useHorizontalScroll('ant-table-body', 300);
//   // eslint-disable-next-line react-hooks/rules-of-hooks
//   const [selectedRow, setSelectedRow] = useState({}); // 存储选中的表格行数据
//   // eslint-disable-next-line react-hooks/rules-of-hooks
//   const [delList, setDelList] = useState([]);
//   // eslint-disable-next-line react-hooks/rules-of-hooks
//   const [listRes, setListRes] = useState(null)


//   const columns = [
//     {
//       title: t("权限组名称"),
//       key: "name",
//       dataIndex: "name",
//       hideInSearch: false,
//     },
//     {
//       title: t("操作"),
//       key: "operation",
//       dataIndex: "operation",
//       hideInSearch: false,
//       fixed: 'right',
//       width: 200,
//       render: (_, record) => {
//         return <>
//           {getPermissionsStatus('vis:level:edit') && <Button type="link" onClick={() => handleOpenDetails(record)}>
//             {t('编辑')}
//           </Button>}

//           {getPermissionsStatus('vis:level:delete') && <Button type="link" danger onClick={() => {
//             confirm({
//               title: t("确认是否删除"),
//               okText: t("common.button.sure"),
//               okType: "danger",
//               cancelText: t("common.confirm.cancelText"),
//               onOk: async () => {

//                 const errorMsg = {
//                   "DMSE00543": "权限组中存在人员或设备，请先清除",
//                   "DMSE00542": "权限组中存在人员或设备，请先清除",
//                 }
//                 try {
//                   let res = await deleteVisLevel(record.id)
//                   res.code === "DMSI0000" ? message.success('操作成功') : message.warn(errorMsg[res.code] || '删除失败')
//                   const { setPageInfo, pageInfo, reload } = actionRef.current
//                   const LastPageCount = calculateLastPageCount(listRes.totalCount, listRes.pageSize, listRes.totalPages)
//                   /**
//                    * 1. 删除的是最后一页
//                    * 2. 删除的数量大于最后一页的总数量
//                    * 3. 页面向前一页
//                    * 
//                    * listRes.totalPages 总页数
//                    * pageInfo.current 当前页
//                    * 
//                    * */

//                   const DEL_LENGTH = 1 // 删除的条数

//                   if (listRes.totalPages === pageInfo.current && DEL_LENGTH >= LastPageCount) {
//                     setPageInfo({
//                       ...pageInfo,
//                       current: pageInfo.current <= 1 ? 1 : pageInfo.current - 1
//                     });
//                   } else {
//                     reload();
//                   }
//                 } catch (error) {

//                 }
//               },
//             });
//           }}>{t('删除')}</Button>}
//         </>
//       }
//     },
//   ];
//   const handleOpenDetails = async (record) => {
//     try {
//       const params = {
//         levelId: record.id,
//         pageSize: 9999,
//         pageNumber: 1
//       }
//       let { accessGroupDoors = [] } = await getDoorList(params) || {}

//       const doorIds = accessGroupDoors.map((e) => e.doorId)

//       setSelectedRow({ ...record, type: 'set', doorIds }); // 更新选中的表格行数据
//       setOpen(true); // 打开DetailsDrawer
//     } catch (error) {
//       setSelectedRow({ ...record, type: 'set' }); // 更新选中的表格行数据
//       setOpen(true); // 打开DetailsDrawer

//     }

//   };

//   // eslint-disable-next-line react-hooks/rules-of-hooks
//   const columnsWithEllipsis = columns.map(column => {
//     const { render, ...rest } = column;
//     const key = column.dataIndex
//     return {
//       ...rest,
//       ellipsis: true,
//       render: (_, record) => {
//         const text = record[key]
//         if (render) return render(_, record)
//         if (!render) return <span title={text}>{text}</span>
//       }
//     };
//   });

//   const onSubmit = async (values) => {
//     let group = selectedRow.type === 'add' ?
//       await addVisLevel({ ...values }) :
//       await updateVisLevel(selectedRow.id, { schedulerId: selectedRow.scheduler_id, ...values })

//     group && message.success('操作成功');

//     values.doorIds && values.doorIds.length > 0 && await addDoor({ levelId: group.id, doorIds: values.doorIds })

//     selectedRow.type === 'set' && delList.length > 0 && await onDeselect(delList)// 删除门禁设备
//     setOpen(false);
//     actionRef.current.reload()


//   }



//   const onDeselect = async (value) => {
//     if (selectedRow.id) {
//       deleteDoor({ levelId: selectedRow.id, doorIds: [...value] })
//     }
//   }
//   return (
//     <div className='vis-invitation-list'>
//       <ProTableCustom
//         columns={columnsWithEllipsis}
//         actionRef={actionRef}
//         request={async (params = {}, sort, filter) => {
//           try {
//             const res = await getVisLevelList({
//               pageNumber: params.current,
//               pageSize: params.pageSize,
//               name: params?.searchParams || "",
//             }) || {};

//             setListRes({
//               len: res.accessGroups ? res.accessGroups.length : 0,
//               pageSize: params.pageSize,
//               totalPages: res.totalPages,
//               totalCount: res.totalCount,
//             })

//             return {
//               data: res.accessGroups || [],
//               success: true,
//               total: res.totalCount,
//             };
//           } catch (error) {

//             return {
//               data: [],
//               success: true,
//               total: 0,
//             };
//           }
//         }}
//         searchPlaceholder={t("card.searchPlaceholder001")}
//         rowKey="id"
//         toolBar={
//           getPermissionsStatus('vis:level:add') ? [
//             <Button
//               key="button"
//               icon={<PlusOutlined />}
//               type="primary"
//               onClick={() => {
//                 setSelectedRow({ name: '', type: 'add', doorIds: [] })
//                 setOpen(true);
//               }}
//             >
//               {t("common.button.add")}
//             </Button>,
//           ] : []
//         }
//       />
//       {open && <PermissionGroupEditing
//         open={open}
//         // onDeselect={onDeselect}
//         onSubmit={onSubmit}
//         getDelList={(val) => {

//           setDelList(val)
//         }}
//         initialValues={selectedRow} // 将选中的表格行数据传递给DetailsDrawer
//         title='详情'
//         onClose={() => { setOpen(false); }}
//       />}

//     </div>
//   )
// }




import React, { useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Button, Modal, message } from "antd";
import { ProTableCustom } from "zlink-front";
import "@/assets/scss/vis.scss";
import useHorizontalScroll from '../../../hook/useHorizontalScroll';
import PermissionGroupEditing from './components/PermissionGroupEditing';
import { PlusOutlined } from "@ant-design/icons";
import { addVisLevel, getVisLevelList, updateVisLevel, deleteVisLevel, addDoor, getDoorList, deleteDoor } from "@/api/acc";
import { getPermissionsStatus, calculateLastPageCount } from "@/utils/index";

const { confirm } = Modal;

export default function Index() {
  const { t } = useTranslation();
  const actionRef = useRef();
  const [open, setOpen] = useState(false);
  useHorizontalScroll('ant-table-body', 300);
  const [selectedRow, setSelectedRow] = useState({});
  const [delList, setDelList] = useState([]);
  const [listRes, setListRes] = useState(null);

  const columns = [
    {
      title: t("vis.permissionGroupName"),
      key: "name",
      dataIndex: "name",
      hideInSearch: false,
    },
    {
      title: t("common.table.detail"),
      key: "operation",
      dataIndex: "operation",
      hideInSearch: false,
      fixed: 'right',
      width: 200,
      render: (_, record) => (
        <>
          {getPermissionsStatus('vis:level:edit') && (
            <Button type="link" onClick={() => handleOpenDetails(record)}>
              {t('common.button.edit')}
            </Button>
          )}
          {getPermissionsStatus('vis:level:delete') && (
            <Button type="link" danger onClick={() => handleDelete(record)}>
              {t('common.button.del')}
            </Button>
          )}
        </>
      ),
    },
  ];

  const handleOpenDetails = async (record) => {
    try {
      const params = {
        levelId: record.id,
        pageSize: 9999,
        pageNumber: 1
      };
      let { accessGroupDoors = [] } = await getDoorList(params) || {};
      const doorIds = accessGroupDoors.map((e) => e.doorId);
      setSelectedRow({ ...record, type: 'set', doorIds });
      setOpen(true);
    } catch (error) {
      setSelectedRow({ ...record, type: 'set' });
      setOpen(true);
    }
  };

  const handleDelete = (record) => {
    confirm({
      title: t("vis.PermissionGroupManagement.confirmDelete"),
      okText: t("common.button.sure"),
      okType: "danger",
      cancelText: t("common.confirm.cancelText"),
      onOk: async () => {
        const errorMsg = {
          "DMSE00543": t("vis.PermissionGroupManagement.permissionGroupContainsPersonsOrDevices"),
          "DMSE00542": t("vis.PermissionGroupManagement.permissionGroupContainsPersonsOrDevices"),
        };
        try {
          let res = await deleteVisLevel(record.id);
          res.code === "DMSI0000" ? message.success(t("common.result.delSuccess")) : message.warn(errorMsg[res.code] || t("common.result.delFail"));
          const { setPageInfo, pageInfo, reload } = actionRef.current;
          const LastPageCount = calculateLastPageCount(listRes.totalCount, listRes.pageSize, listRes.totalPages);
          const DEL_LENGTH = 1;
          if (LastPageCount === DEL_LENGTH) {// 点击删除后，当前页为最后一页且删除的条数等于最后一页的条数，直接刷新
            reload();
          }
          if (listRes.totalPages === pageInfo.current && DEL_LENGTH >= LastPageCount) {
            setPageInfo({
              ...pageInfo,
              current: pageInfo.current <= 1 ? 1 : pageInfo.current - 1
            });
          } else {
            reload();
          }
        } catch (error) {
          message.error(t("common.result.delFail"));
        }
      },
    });
  };

  const columnsWithEllipsis = columns.map(column => {
    const { render, ...rest } = column;
    const key = column.dataIndex;
    return {
      ...rest,
      ellipsis: true,
      render: (_, record) => {
        const text = record[key];
        return render ? render(_, record) : <span title={text}>{text}</span>;
      }
    };
  });

  const onSubmit = async (values) => {
    let group = selectedRow.type === 'add' ?
      await addVisLevel({ ...values }) :
      await updateVisLevel(selectedRow.id, { schedulerId: selectedRow.scheduler_id, ...values });

    group && message.success(t("common.result.saveSuccess"));

    values.doorIds && values.doorIds.length > 0 && await addDoor({ levelId: group.id, doorIds: values.doorIds });

    selectedRow.type === 'set' && delList.length > 0 && await onDeselect(delList);
    setOpen(false);
    actionRef.current.reload();
  };

  const onDeselect = async (value) => {
    if (selectedRow.id) {
      deleteDoor({ levelId: selectedRow.id, doorIds: [...value] });
    }
  };

  return (
    <div className='vis-invitation-list'>
      <ProTableCustom
        columns={columnsWithEllipsis}
        actionRef={actionRef}
        request={async (params = {}, sort, filter) => {
          try {
            const res = await getVisLevelList({
              pageNumber: params.current,
              pageSize: params.pageSize,
              name: params?.searchParams || "",
            }) || {};

            setListRes({
              len: res.accessGroups ? res.accessGroups.length : 0,
              pageSize: params.pageSize,
              totalPages: res.totalPages,
              totalCount: res.totalCount,
            });

            return {
              data: res.accessGroups || [],
              success: true,
              total: res.totalCount,
            };
          } catch (error) {
            return {
              data: [],
              success: true,
              total: 0,
            };
          }
        }}
        searchPlaceholder={t("vis.PermissionGroupManagement.searchPlaceholder")}
        rowKey="id"
        toolBar={
          getPermissionsStatus('vis:level:add') ? [
            <Button
              key="button"
              icon={<PlusOutlined />}
              type="primary"
              onClick={() => {
                setSelectedRow({ name: '', type: 'add', doorIds: [] });
                setOpen(true);
              }}
            >
              {t("common.button.add")}
            </Button>,
          ] : []
        }
      />
      {open && (
        <PermissionGroupEditing
          open={open}
          onSubmit={onSubmit}
          getDelList={(val) => setDelList(val)}
          initialValues={selectedRow}
          title={t("common.table.detail")}
          onClose={() => setOpen(false)}
        />
      )}
    </div>
  );
}
