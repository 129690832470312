import React from "react";
import ReactDOM from "react-dom";
import "./assets/scss/common.scss";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store/store";
import ZRouter from "./router";
import "@/i18n"
import "antd/dist/antd.css";
import "@ant-design/pro-table/dist/table.css";
import "@ant-design/pro-list/dist/list.css";
import "@ant-design/pro-form/dist/form.css";
import { zklinkAppSDK } from 'zlink-front';
import { UPDATELANGUAGE } from "@/store/modules/settings";

const render = ( props, Component = ZRouter ) => {
  ReactDOM.render(
    <Provider store={store}>
      <Component store={store} />
    </Provider>,
    props?.container ? props?.container.querySelector('#root') : document.getElementById('root')
  )
}

zklinkAppSDK.config({
  appId: '000000008466126c01846612971d0037',
  type: 'react',
  render: render,
  props: {}
})

export const mount = async(props) => {
  props.onGlobalStateChange((state) => store.dispatch({ type: UPDATELANGUAGE, data: state.language }));
  zklinkAppSDK.mount(props)
}
export const unmount = async(props) => zklinkAppSDK.unmount(props)
export const bootstrap = async(props) => zklinkAppSDK.bootstrap(props)


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
