import React, { useEffect, useState } from "react";
import { Drawer, Card, Tag, Row, Col } from "antd";
import { getVisAuth } from "@/api/vis";
import moment from "moment";

import ProForm, { ProFormText } from "@ant-design/pro-form";
import { useTranslation } from "react-i18next";
import { layout } from "zlink-front";
import Text from "antd/lib/typography/Text";
const { defaultFormLayout } = layout;

const DetailDrawer = (props) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [dataInfo, setDataInfo] = useState(null);

  const getVisAuthInfo = async (visAuthId) => {
    if (visAuthId !== "") {
      const visAuthInfoRes = await getVisAuth(visAuthId);
      setDataInfo(visAuthInfoRes);
    }
  };

  useEffect(() => {
    setShow(props.visible);
    getVisAuthInfo(props.visAuthId);
  }, [props.visible]); // eslint-disable-line react-hooks/exhaustive-deps

  const onClose = () => {
    setShow(false);
    props.close();
  };
  return (
    <>
      <Drawer
        title={t("common.table.detail")}
        {...defaultFormLayout.drawer}
        {...defaultFormLayout.bodyStyle}
        onClose={onClose}
        open={show}
        className="zk-vis-info-drawer"
      > {
          dataInfo !== null ?
            <ProForm
              {...defaultFormLayout.form}
              submitter={false}
              request={async () => {
                return {
                  ...dataInfo,
                  time: moment(dataInfo.authStartTime).format("YYYY-MM-DD HH:mm") + " ~ " + moment(dataInfo.authEndTime).format("YYYY-MM-DD HH:mm"),
                  status: dataInfo.enable && moment(dataInfo.authEndTime) > Date.now() ? t("vis.visitorAuth.DetailDrawerInfo.statusNormal") : t("vis.visitorAuth.DetailDrawerInfo.statusExpired"),
                };
              }}
            >
              <div className="zklink-drawer-title">
                <Text type="success">|</Text>
                {t("vis.visitorAuth.DetailDrawerInfo.info")}
              </div>
              <ProFormText
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 18 }}
                name={["visName"]}
                label={t("vis.visitorAuth.DetailDrawerInfo.name")}
                disabled
              />
              <ProFormText
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 18 }}
                name={["visPhone"]}
                label={t("vis.visitorAuth.DetailDrawerInfo.phone")}
                disabled
              />
              <ProFormText
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 18 }}
                name={["time"]}
                label={t("vis.visitorAuth.DetailDrawerInfo.authorizationTime")}
                disabled
              />
              <ProFormText
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 18 }}
                name={["status"]}
                label={t("vis.visitorAuth.DetailDrawerInfo.status")}
                disabled
              />
              <ProFormText
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 18 }}
                name={["authCode"]}
                label={t("vis.visitorAuth.DetailDrawerInfo.password")}
                disabled
              />
              <Row>
                <Col className="gutter-row" span={5}>
                  <div>{t("vis.visitorAuth.DetailDrawerInfo.authorizationDevices")}:</div>
                </Col>
                <Col className="gutter-row" span={18}>
                  {dataInfo.visitorAuthDeviceList &&
                    !!dataInfo.visitorAuthDeviceList.length && (
                      <Card>
                        {dataInfo.visitorAuthDeviceList.map((item, idx) => {
                          return <Tag key={item.deviceSn} className=""> {item.deviceSn} </Tag>;
                        })}
                      </Card>
                    )}
                  {!!dataInfo.visitorAuthDeviceList &&
                    dataInfo.visitorAuthDeviceList.length === 0 && (
                      <div>{t("vis.visitorAuth.DetailDrawerInfo.allDevice")}</div>
                    )}
                </Col>
              </Row>
            </ProForm>
            : <></>
        }
      </Drawer>
    </>
  );
};

export default DetailDrawer;
